/* Headline */

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-headline {
	margin-top: -72px;
}
.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-headline
	h2 {
	font-size: 32px !important;
	line-height: 36px !important;
	padding-bottom: 0 !important;
}
.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-headline
	h2::after {
	display: none !important;
}
.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-headline
	h3::after {
	display: none !important;
}

/* Headline + Links */

.mco-view-component-container-basic-headline--1 + .mco-view-component-container-basic-links {
	margin-top: -60px;
}

/* Headline & Text */

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-text {
	margin-top: -32px;
}

/* Dropdown */

.mco-view-component-container-basic-dropdown-group
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -80px;
}

/* Headline + Trail Detail Facts */

.mco-view-component-container-basic-headline
	+ .mco-view-component-container-extension-trail-facts {
	margin-top: -40px !important;
}

/* Trail Detail Facts + Logos */

.mco-view-component-container-extension-trail-facts
	+ .mco-view-component-container-extension-logos {
	margin-top: -40px !important;
}

@media (min-width: 600px) {
	/* Imagetext */

	.mco-view-component-container-basic-imagetext
		+ .mco-view-component-container-basic-imagetext {
		margin-top: -80px;
	}

	/* Singlteaser */

	.mco-view-component-container-basic-singleteaser
		+ .mco-view-component-container-basic-singleteaser {
		margin-top: -80px;
	}

	.mco-view-component-container-basic-external-singleteaser
		+ .mco-view-component-container-basic-external-singleteaser {
		margin-top: -80px;
	}
}

@media (min-width: 960px) {
	.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-headline
		h2 {
		font-size: 40px !important;
		line-height: 44px !important;
	}
}
