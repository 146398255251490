/* Headline */

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--mco-palette-secondary-dark) !important;
}
.mco-headline {
	text-align: center !important;
}

@media (max-width: 500px) {
	.mco-typography-headline-medium {
		hyphens: auto;
	}
}

h2.mco-headline,
h3.mco-headline,
.mco-list-01__headline,
.mco-references-01__headline,
.mco-table-01__headline,
.mco-references-03__headline {
	padding-bottom: 24px;
	position: relative;
	text-align: center;
}
.mco-list-01__headline,
.mco-references-01__headline,
.mco-table-01__headline,
.mco-references-03__headline {
	margin-bottom: 40px !important;
}
h2.mco-headline::after,
h3.mco-headline::after,
.mco-list-01__headline::after,
.mco-references-01__headline::after,
.mco-table-01__headline::after,
.mco-references-03__headline::after {
	background-image: url("/public/img/highlight-xl.svg");
	background-size: contain;
	background-repeat: no-repeat;
	bottom: 0;
	content: "";
	height: 14px;
	left: 50%;
	position: absolute;
	transform: translate(-50%, 0);
	width: 222px;
}
/* h3.mco-headline {
	padding-bottom: 32px;
	position: relative;
	text-align: center;
}
h3.mco-headline::after {
	background-image: url("/public/img/highlight.svg");
	background-size: contain;
	background-repeat: no-repeat;
	bottom: 0;
	content: "";
	height: 8px;
	left: 50%;
	position: absolute;
	transform: translate(-50%, 0);
	width: 115px;
} */

/* Text */
.mco-text-01 a {
	font-weight: 400 !important;
	text-decoration: underline !important;
}

/* Aufzählung */

.mco-list-01__list-item svg {
	margin-top: 2px !important;
}

/* Bild-Text Kombi */

.mco-imagetext-03 {
	padding-bottom: 40px;
	position: relative;
}
.mco-imagetext-03::before {
	background: #ffffff;
	content: "";
	height: 64px;
	left: 0;
	position: absolute;
	pointer-events: none;
	top: 0;
	width: 100%;
}
.mco-imagetext-03--right .mco-imagetext-03__image {
	padding-left: 24px !important;
}
.mco-imagetext-03--right .mco-imagetext-03__image img {
	-webkit-mask: url("/public/img/mask-skew-v2.png");
	mask: url("/public/img/mask-skew-v2.png");
	-webkit-mask-size: cover;
	mask-size: cover;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	min-height: 262px;
}
.mco-imagetext-03--left .mco-imagetext-03__image {
	padding-right: 24px !important;
}
.mco-imagetext-03--left .mco-imagetext-03__image img {
	-webkit-mask: url("/public/img/mask-skew.png");
	mask: url("/public/img/mask-skew.png");
	-webkit-mask-size: cover;
	mask-size: cover;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: top right;
	mask-position: top right;
	min-height: 262px;
}
.mco-imagetext-03__content {
	margin-top: -8px !important;
	padding: 0 24px !important;
}
.mco-imagetext-03--left .mco-imagetext-03__content {
	margin-top: -8px !important;
}
.mco-imagetext-03__title::after {
	display: none !important;
}
.mco-imagetext-03__title::before {
	background-image: url("/public/img/highlight.svg");
	background-size: contain;
	background-repeat: no-repeat;
	bottom: 0;
	content: "";
	height: 8px;
	left: 0;
	position: absolute;
	width: 115px;
}

@media (min-width: 600px) {
	.mco-imagetext-03 {
		padding-bottom: 40px !important;
		padding-top: 80px !important;
	}
	.mco-imagetext-03::before {
		display: none !important;
	}
	.mco-imagetext-03 > .MuiGrid-root {
		flex-wrap: unset !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__content,
	.mco-imagetext-03--left .mco-imagetext-03__content {
		margin-top: 0 !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__image {
		padding-left: 0 !important;
	}
	.mco-imagetext-03__image img {
		-webkit-mask-size: auto !important;
		mask-size: auto !important;
	}
}

@media (min-width: 960px) {
	.mco-imagetext-03--right .mco-imagetext-03__content,
	.mco-imagetext-03--left .mco-imagetext-03__content {
		min-height: 526px !important;
		max-width: 40% !important;
		flex-basis: 40% !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__content {
		padding-right: 48px !important;
	}
	.mco-imagetext-03--left .mco-imagetext-03__content {
		padding-left: 48px !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__image {
		padding-left: 0 !important;
		max-width: 60% !important;
		flex-basis: 60% !important;
	}
	.mco-imagetext-03--left .mco-imagetext-03__image {
		padding-right: 0 !important;
		max-width: 60% !important;
		flex-basis: 60% !important;
	}
}

@media (min-width: 1488px) {
	.mco-imagetext-03 {
		padding-bottom: 80px !important;
	}
	.mco-imagetext-03--right > .MuiGrid-root {
		max-width: 1920px;
		margin-left: calc(50% - 720px) !important;
	}
	.mco-imagetext-03--left > .MuiGrid-root {
		padding-right: calc(50% - 720px) !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__content,
	.mco-imagetext-03--left .mco-imagetext-03__content {
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__content {
		padding-left: 0 !important;
		padding-right: 56px !important;
	}
	.mco-imagetext-03--left .mco-imagetext-03__content {
		padding-left: 56px !important;
		padding-right: 0 !important;
	}
	.mco-imagetext-03--left .mco-imagetext-03__image {
		max-width: 800px !important;
	}
	.mco-imagetext-03__image img {
		width: auto !important;
	}
}

@media (min-width: 1700px) {
	.mco-imagetext-03__content {
		max-width: 800px !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__content {
		padding-right: 80px !important;
	}
	.mco-imagetext-03--left .mco-imagetext-03__content {
		padding-left: 80px !important;
	}
}

/* Singleimage */

.mco-singleimage-04__content {
	padding: 56px 24px !important;
	z-index: 2 !important;
}
.mco-singleimage-04__content-wrapper {
	background: var(--mco-palette-secondary-light) !important;
	border-radius: 8px;
	mix-blend-mode: multiply;
}
.mco-singleimage-04__content-title {
	margin-bottom: 0 !important;
}
.mco-singleimage-04__content-title + .mco-singleimage-04__content-text {
	margin-top: 16px !important;
}
.mco-singleimage-04__image::before {
	background-image: url("/public/img/mask-top.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	content: "";
	height: 80px;
	left: -2px;
	position: absolute;
	pointer-events: none;
	top: -2px;
	right: -2px;
	z-index: 1;
}
.mco-singleimage-04__image::after {
	background-image: url("/public/img/mask-bottom.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	content: "";
	height: 80px;
	left: -2px;
	position: absolute;
	pointer-events: none;
	bottom: -2px;
	right: -2px;
	z-index: 1;
}

@media (min-width: 960px) {
	.mco-singleimage-04__content {
		padding: 80px 40px !important;
	}
	.mco-singleimage-04__content-wrapper {
		padding: 32px !important;
	}
}

/* Singleteaser */

.mco-singleteaser-03 {
	padding-bottom: 40px;
	position: relative;
}
.mco-singleteaser-03::before {
	background: #ffffff;
	content: "";
	height: 64px;
	left: 0;
	position: absolute;
	pointer-events: none;
	top: 0;
	width: 100%;
}
.mco-singleteaser-03--right .mco-singleteaser-03__image {
	padding-left: 24px !important;
}
.mco-singleteaser-03--right .mco-singleteaser-03__image img {
	-webkit-mask: url("/public/img/mask-skew-v2.png");
	mask: url("/public/img/mask-skew-v2.png");
	-webkit-mask-size: cover;
	mask-size: cover;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	min-height: 262px;
}
.mco-singleteaser-03--left .mco-singleteaser-03__image {
	padding-right: 24px !important;
}
.mco-singleteaser-03--left .mco-singleteaser-03__image img {
	-webkit-mask: url("/public/img/mask-skew.png");
	mask: url("/public/img/mask-skew.png");
	-webkit-mask-size: cover;
	mask-size: cover;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: top right;
	mask-position: top right;
	min-height: 262px;
}
.mco-singleteaser-03__content {
	margin-top: -8px !important;
	padding: 0 24px !important;
}
.mco-singleteaser-03--left .mco-singleteaser-03__content {
	margin-top: -8px !important;
}
.mco-singleteaser-03__title::after {
	display: none !important;
}
.mco-singleteaser-03__title::before {
	background-image: url("/public/img/highlight.svg");
	background-size: contain;
	background-repeat: no-repeat;
	bottom: 0;
	content: "";
	height: 8px;
	left: 0;
	position: absolute;
	width: 115px;
}

@media (min-width: 600px) {
	.mco-singleteaser-03 {
		padding-bottom: 40px !important;
		padding-top: 80px !important;
	}
	.mco-singleteaser-03::before {
		display: none !important;
	}
	.mco-singleteaser-03 .mco-singleteaser-03__container {
		flex-wrap: unset !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content,
	.mco-singleteaser-03--left .mco-singleteaser-03__content {
		margin-top: 0 !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__image {
		padding-left: 0 !important;
	}
	.mco-singleteaser-03__image img {
		-webkit-mask-size: auto !important;
		mask-size: auto !important;
	}
}

@media (min-width: 960px) {
	.mco-singleteaser-03--right .mco-singleteaser-03__content,
	.mco-singleteaser-03--left .mco-singleteaser-03__content {
		min-height: 526px !important;
		max-width: 40% !important;
		flex-basis: 40% !important;
	}
	.mco-singleteaser-03--left .mco-singleteaser-03__content {
		margin-left: auto !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content {
		margin-right: auto !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content {
		padding-right: 48px !important;
	}
	.mco-singleteaser-03--left .mco-singleteaser-03__content {
		padding-left: 48px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__image {
		padding-left: 0 !important;
		max-width: 60% !important;
		flex-basis: 60% !important;
	}
	.mco-singleteaser-03--left .mco-singleteaser-03__image {
		padding-right: 0 !important;
		max-width: 60% !important;
		flex-basis: 60% !important;
	}
}

@media (min-width: 1488px) {
	.mco-singleteaser-03 {
		padding-bottom: 80px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__container {
		max-width: 1920px;
		margin-left: calc(50% - 720px) !important;
	}
	.mco-singleteaser-03--left .mco-singleteaser-03__container {
		padding-right: calc(50% - 720px) !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content,
	.mco-singleteaser-03--left .mco-singleteaser-03__content {
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content {
		padding-left: 0 !important;
		padding-right: 56px !important;
	}
	.mco-singleteaser-03--left .mco-singleteaser-03__content {
		padding-left: 56px !important;
		padding-right: 0 !important;
	}
	.mco-singleteaser-03--left .mco-singleteaser-03__image {
		max-width: 800px !important;
	}
	.mco-singleteaser-03__image img {
		width: auto !important;
	}
}

@media (min-width: 1700px) {
	.mco-singleteaser-03__content {
		max-width: 800px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content {
		padding-right: 80px !important;
	}
	.mco-singleteaser-03--left .mco-singleteaser-03__content {
		padding-left: 80px !important;
	}
}

/* Gallery */

.mco-gallery-01 .mco-gallery-01__wrapper {
	display: grid;
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
}

@media (min-width: 600px) {
	.mco-gallery-01 .mco-gallery-01__wrapper {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

/* Links */

.mco-references-03__list {
	justify-content: center !important;
}

/* Table */

.mco-table-01__cell {
	font-family: "Roboto" !important;
	font-size: 16px;
}
th.mco-table-01__cell {
	background: var(--mco-palette-background-light);
	font-size: 18px;
}

/* Video */

.mco-video-02__play-icon svg path {
	fill: #ffffff !important;
}

/* Dropdown */

.mco-dropdown-01 {
	border-top: none !important;
	border-bottom: 1px solid var(--mco-palette-secondary-dark) !important;
}
.mco-dropdown-01__headline {
	color: var(--mco-palette-secondary-dark) !important;
}
.mco-dropdown-01__summary {
	background: var(--mco-palette-background-light) !important;
	min-height: 64px !important;
}
.mco-dropdown-01 .MuiButtonBase-root svg path {
	fill: var(--mco-palette-secondary-dark) !important;
}
.mco-dropdown-01__details {
	padding-top: 40px !important;
}
.mco-dropdown-01__details > div {
	display: flex;
	flex-direction: column;
	gap: 80px;
	padding-bottom: 24px;
}

/* Form */

.mco-form .mco-form-button-wrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
}
.mco-form-headline p {
	color: var(--mco-palette-secondary-dark) !important;
}

/* Trailmap */

@media (min-width: 600px) {
	.mco-view-component-container-extension-trail-map {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

/* Social Icons */
/* 
.mco-view-component-social-icons {
	justify-content: center !important;
}
.mco-view-component-social-icons__item a {
	background: var(--mco-palette-secondary-light) !important;
}
.mco-view-component-social-icons__item a:hover {
	background: var(--mco-palette-secondary-main) !important;
} */

/* Gallery02 */

.mco-gallery-02-swiper-nav__next,
.mco-gallery-02-swiper-nav__prev {
	border-radius: unset !important;
	height: 48px !important;
	width: 48px !important;
}

@media (min-width: 960px) {
	.mco-gallery-02-swiper-nav__next,
	.mco-gallery-02-swiper-nav__prev {
		height: 64px !important;
		width: 64px !important;
	}
}

/* Interaktionslayer */

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	background: var(--mco-palette-primary-main);
	border: 0;
	border-radius: 8px;
	box-shadow: none !important;
	cursor: pointer;
	font-family: "Roboto" !important;
	font-size: 20px;
	font-weight: 500;
	height: 48px;
	letter-spacing: 0.2px;
	line-height: 48px;
	min-width: 64px;
	padding: 0 24px;
	text-shadow: 0 1px 2px rgb(0 0 0 / 24%) !important;
	text-align: center;
	text-transform: unset;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: var(--mco-palette-primary-dark);
}
.micado-ial__notification .micado-ial__close:hover .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close:hover .micado-svg-icon svg path {
	fill: #ffffff;
}
.micado-ial-item-notification__button--is-icon:hover {
	background: var(--mco-palette-primary-dark);
}
.micado-ial__close,
.micado-overlay__close {
	background: #ededed !important;
}
.micado-ial__close:hover,
.micado-overlay__close:hover {
	background: var(--mco-palette-primary-main) !important;
	color: #ffffff !important;
}
.micado-ial__close:hover svg path,
.micado-overlay__close:hover svg path {
	fill: #ffffff !important;
}
.micado-ial-item-contact__headline,
.micado-ial-item-countdown__headline,
.micado-ial-item-message__headline,
.micado-ial-item-newsletter__headline {
	color: var(--mco-palette-primary-main) !important;
	font-family: "Roboto" !important;
	font-size: 32px !important;
}
.micado-ial-item-contact__headline,
.micado-ial-item-newsletter__headline {
	text-align: left !important;
}
.micado-ial-item-contact__text,
.micado-ial-item-newsletter__text {
	text-align: left !important;
}
.micado-ial-item-message__buttons {
	justify-content: flex-end !important;
}
