@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/roboto-v30-latin-regular.woff2") format("woff2"),
		url("../fonts/roboto-v30-latin-regular.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: url("../fonts/roboto-v30-latin-italic.woff2") format("woff2"),
		url("../fonts/roboto-v30-latin-italic.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: url("../fonts/roboto-v30-latin-500.woff2") format("woff2"),
		url("../fonts/roboto-v30-latin-500.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/roboto-v30-latin-700.woff2") format("woff2"),
		url("../fonts/roboto-v30-latin-700.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: italic;
	font-weight: 700;
	src: url("../fonts/roboto-v30-latin-700italic.woff2") format("woff2"),
		url("../fonts/roboto-v30-latin-700italic.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Pragati Narrow";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/pragati-narrow-v13-latin-regular.woff2") format("woff2"),
		url("../fonts/pragati-narrow-v13-latin-regular.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Pragati Narrow";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/pragati-narrow-v13-latin-700.woff2") format("woff2"),
		url("../fonts/pragati-narrow-v13-latin-700.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Aracne Ultra Condensed";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/aracneultracondreg.woff2") format("woff2"),
		url("../fonts/aracneultracondreg.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Aracne Condensed";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/aracnecondreg.woff2") format("woff2"),
		url("../fonts/aracnecondreg.woff") format("woff");
}
